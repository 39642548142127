import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/companydesc.scss'

import goddardsIntroLogo from '../../assets/images/goddardsIntroImage.png'

function CompanyDesc() {
    return (
        <div className="companyDescContainer">
            <h2>Bespoke Solution for Leading Fashion Retail Group</h2>
            <div className="imageBox">
                <div className="freshCheckLogoContainer">
                    <Image src={goddardsIntroLogo} className="fcLogo" />
                </div>
                <h3>About Goddards</h3>
                
                <p>Established in 1895, Goddards - part of the Goddards and until recently known as Robert Goddard - has a rich history as one of the UK's best-loved independent designer fashion chains.</p>
                <p>From humble beginnings in King's Lynn, Norfolk, the business has grown over the past 120 years to today having 10 signature stores across Eastern and Central England, with many more in the pipeline.</p>
                <p>Synonymous with premium quality and exceptional customer service, the company is a retailer of choice for some of the world's most exclusive and high profile brands and fashion houses including Ralph Lauren, Barbour, and Hugo Boss, amongst many others.</p>
                <p>Carefully curated seasonal collections are beautifully showcased in each of the individually designed stores, offering discerning shoppers an unrivalled shopping experience.</p>
                
            </div>
        </div>
    );
}

export default CompanyDesc;