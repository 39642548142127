import React from 'react';
import { Row } from 'react-bootstrap';

import ServiceContactComp from '../components/servicesComponents/ServiceContactComp'
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/web portal.jpg'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Collaborative Workspace.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Customized Functionality.png"
import serviceIcon3 from "../assets/images/serviceIcons/-_Enhanced -User Experience.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'


function BusinessWebPortals() {

    const benefitsArray = [
        {
            title: "Collaborative Workspace",
            desc: "Facilitate seamless communication and collaboration among your team members. Our Business Web Portals provide a centralized workspace where teams can share information, collaborate on projects, and stay connected.",
            cardImage: serviceIcon1
        },
        {
            title: "Customized Functionality",
            desc: "No two businesses are alike, and neither should their portals be. Our Business Web Portals are customizable to align with your specific needs, ensuring that the platform serves as a valuable asset to your business operations.",
            cardImage: serviceIcon2
        },
        {
            title: "Enhanced User Experience",
            desc: "Provide your users with an intuitive and user-friendly interface. Our portals are designed with the end-user in mind, promoting efficiency and a positive user experience.",
            cardImage: serviceIcon3
        }
    ]

    return (
        <div>
            <Row className="row-center">
                <ServiceDescComp pageTitle="Digital Efficiency: Elevate Your Business with a Business Web Portal"
                    subDesc="We understand that your business requires a digital hub to centralize operations and enhance collaboration. EJ Software can help build your business a customized platform designed to streamline communication, improve efficiency, and drive your business forward."
                    serviceTitle="What is a Business Web Portal"
                    desc="A Business Web Portal is a centralized online platform that brings together diverse business processes, data, and communication channels in one accessible location. Our portals are tailored to meet the unique needs of your business, providing a cohesive digital environment."
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp desc1={`"Business web portals serve as digital gateways to a world of streamlined operations and enhanced connectivity. They are the virtual hubs where information flows seamlessly, collaborations thrive, and efficiency takes center stage. In the realm of business web portals, each click opens a door to a more interconnected, accessible, and empowered way of conducting business in the digital age."`} />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing a Business Web Portal" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
            <Row className="row-center">
                <ServiceContactComp title="Transform your business digitally"
                    desc="Contact us today to explore how a customized Business Web Portal can propel your business into the future." />
            </Row>
        </div>
    );
}

export default BusinessWebPortals