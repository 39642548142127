import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/companydesc.scss'

import freshCheckLogo from '../../assets/images/case-study/fc-logo.jpg'

function CompanyDesc() {
    return (
        <div className="companyDescContainer">
            <h2>Helping hygiene innovator make contamination visible</h2>
            <div className="imageBox">
                <div className="freshCheckLogoContainer">
                    <Image src={freshCheckLogo} className="fcLogo" />
                </div>
                <h3>About FreshCheck</h3>
                
                <p>Established in 2015 by Imperial College London PhD alumni Alex Bond and John Simpson, FreshCheck - <a href="https://www.freshcheck.co.uk" target="_blank" rel="noreferrer">www.freshcheck.co.uk</a> - is an innovator in the field of hygiene verification.</p>
                <p>Having initially developed and launched a unique contamination detection spray, the company is the first to create industry-leading colour changing detection swabs that enable businesses where hygiene is a priority - such as in the food production sector - to quickly and easily ensure their equipment, premises, and surfaces are hygienically clean and microbially safe.</p>
                <p>Simpler, cheaper, and safer to use than traditional adenosine triphosphate (ATP) and microbiological tests, the patented swabs provide a clear result in less than 30 seconds and do away with the need for expensive and fragile equipment or time-consuming laboratory tests (allowing clients to increase their productivity whilst reducing costs).</p>
                <p>With a dedicated mobile phone app and a web portal that provides live and historic report data, the swab-based Hygiene Verification System is now being rolled out beyond early adopters, with other developments - such as image detection AI capabilities - in the pipeline.</p>
                
            </div>
        </div>
    );
}

export default CompanyDesc;