import React from 'react';
import MailingList from '../utilityComps/MailingList'
import {Image} from 'react-bootstrap'
import '../../assets/css/footer.scss';

import { Link } from 'react-router-dom';


import linkedinicon from '../../assets/images/linkedinIconW.svg'
import facebookicon from '../../assets/images/facebookIconW.svg'
import instagramicon from '../../assets/images/instagramIconW.svg'
import xicon from '../../assets/images/xIconW.svg'

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footerContainerMain">
            <div className="getInTouch">

                <div className="centerThis">
                    <p>GET IN TOUCH</p>
                    <div className="lineDiv"></div>
                </div>
                <div>
                    <p className="joinUs">Join our community and start your journey!</p>
                </div>
                <div className="inputHolder">
                    <MailingList />
                </div>

            </div>

            <div className="footerContainer">
                <div className="textBoxLeft">
                    <p>&copy; {currentYear} EJ Software Ltd // UK (Companies House)</p>
                    <p>Company Number: 12856964</p>
                    
                    <div>
                        <Link to="/privacy" className="movePositionOne">
                            <p>Privacy Policy</p>
                        </Link>
                    </div>
                </div>
                <div className="socContainerFoot">
                    <a href="https://www.facebook.com/profile.php?id=100094303763892" target="_blank" rel="noreferrer"><Image src={facebookicon} className="socIconFoot" /></a>
                    <a href="https://www.instagram.com/ejsoftware/" target="_blank" rel="noreferrer"><Image src={instagramicon} className="socIconFoot" /></a>
                    <a href="https://www.linkedin.com/company/91577331" target="_blank" rel="noreferrer"><Image src={linkedinicon} className="socIconFoot" /></a>
                    <a href="https://www.twitter.com/ejsoftware" target="_blank" rel="noreferrer"><Image src={xicon} className="socIconFoot" /></a>
                </div>
                <div className="textBoxRight">
                    <p>Contact No: 01223 944093</p>
                    <p><a href="mailto: hello@ej-software.co.uk">Email: hello@ej-software.co.uk</a></p>
                </div>
                <div className="socContainerFootLazy">
                    <a href="https://www.facebook.com/profile.php?id=100094303763892" target="_blank" rel="noreferrer"><Image src={facebookicon} className="socIconFoot" /></a>
                    <a href="https://www.instagram.com/ejsoftware/" target="_blank" rel="noreferrer"><Image src={instagramicon} className="socIconFoot" /></a>
                    <a href="https://www.linkedin.com/company/91577331" target="_blank" rel="noreferrer"><Image src={linkedinicon} className="socIconFoot" /></a>
                    <a href="https://www.twitter.com/ejsoftware" target="_blank" rel="noreferrer"><Image src={xicon} className="socIconFoot" /></a>
                   
                </div>
                <div className="pushMeSmall">
                    <Link to="/privacy">
                        <p>Privacy Policy</p>
                    </Link>
                </div>
                
            </div>
        </div>
        
    );
};

export default Footer
