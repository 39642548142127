
import { Row } from 'react-bootstrap';
import CaseStudies from '../components/home/CaseStudies';
function CaseStudy() {
    return (
        <div>
            <Row className="row-center">
                <CaseStudies />
            </Row>
        </div>
    )
}

export default CaseStudy