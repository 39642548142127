import ContactComp from '../components/ContactUsPage/ContactComp'
import {  Row } from 'react-bootstrap';

function ContactUsPage() {
    return (
        <div>
            <Row className="row-center">
                <ContactComp />
            </Row>
        </div>
    )
}

export default ContactUsPage