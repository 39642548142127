import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import '../../assets/css/offering.scss';
import crms from '../../assets/images/serviceIcons/-_Strategic Planning.png'
import retail from '../../assets/images/serviceIcons/-_Unified Retail- Management.png'
import iot from '../../assets/images/serviceIcons/-_Predictive Analytics.png'
import web from '../../assets/images/serviceIcons/-_Customized Functionality.png'
import ai from '../../assets/images/serviceIcons/-_Continuous Learning-.png'
import consultancy from '../../assets/images/serviceIcons/-_Consultancy Services.png'

function Offering() {


    return (
        <div className="offeringContainer">
            <h2>What We Do</h2>
            <p className="offeringDesc">EJ Software crafts tailored software solutions, from small internal systems to complex, scalable platforms,
                ensuring your ideas are brought to life with efficiency and precision.</p>
            <div className="iconContainer">
                <Link className="iconCard" to="/services-bespoke-crm">
                    <div>
                        <Image src={crms} className="whatWeImage" />
                        <p>Bespoke CRMs</p>
                    </div>
                </Link>
                <Link className="iconCard" to="/services-retail-and-ecommerce-solutions">
                    <div>
                        <Image src={retail} className="whatWeImage" />
                        <p>Retail Solutions</p>
                    </div>
                </Link >
                <Link className="iconCard" to="/services-iot">
                    <div>
                        <Image src={iot} className="whatWeImage" />
                        <p>Internet of Things (IoT)</p>
                    </div>
                </Link>
                <Link className="iconCard" to="/services-business-web-portals">
                    <div>
                        <Image src={web} className="whatWeImage" />
                        <p>Business Web Portals</p>
                    </div>
                </Link>
                <Link className="iconCard" to="/services-ai-and-machine-learning-solutions">
                    <div>
                        <Image src={ai} className="whatWeImage" />
                        <p>AI & Machine Learning</p>
                    </div>
                </Link>
                <Link className="iconCard" to="/services-business-process-and-software-consulting">
                    <div>
                        <Image src={consultancy} className="whatWeImage" />
                        <p>Business Consultancy</p>
                    </div>
                </Link >
            </div>
        </div>
    );
};

export default Offering
