import React from 'react';
import '../../assets/css/whoweare.scss';
import { Image } from 'react-bootstrap';

import daBoyz from '../../assets/images/daBoyz.jpg'

function WhoWeAre() {
    return (
        <div className="segTwo">
            <div className="segTwoLeft">
                <Image src={daBoyz} className="daBoyzWorking"/>
            </div>
            <div className="segTwoRight">
                <h2>Building Strong Relationships: Your Success, Our Commitment</h2>
                <p>Our team goes beyond providing services - we forge lasting partnerships. Our commitment to your success is rooted in a collaborative approach, where we actively engage with your business at every step. We take the time to understand the intricacies of your goals, challenges, and aspirations, ensuring that the solutions we deliver are not just effective but deeply aligned with your unique requirements.</p>
                <p>Open communication is the cornerstone of our relationships. We value transparency, keeping you informed about project progress, challenges, and opportunities. Your feedback is not only welcomed but actively sought, fostering an environment of collaboration that ensures mutual growth. With EJ Software, your success isn't just a goal; it's a commitment we uphold with tailored solutions and ongoing support, adapting alongside your business to propel it towards sustained growth and prosperity.</p>
            </div>
        </div>
    )
}

export default WhoWeAre;