import React from 'react';

function BlogHistory(props) {
    const groupBlogsByYearAndMonth = (blogs) => {
        const groupedBlogs = {};

        blogs.forEach(blog => {
            const date = new Date(blog.date);
            if (isNaN(date.getTime())) {
                console.error(`Invalid date format for blog with ID: ${blog.id}`);
                return;
            }

            const year = date.getFullYear();
            const month = date.toLocaleString('default', { month: 'long' });

            if (!groupedBlogs[year]) {
                groupedBlogs[year] = {};
            }

            if (!groupedBlogs[year][month]) {
                groupedBlogs[year][month] = [];
            }

            groupedBlogs[year][month].push(blog);
        });

        return groupedBlogs;
    };

    const groupedBlogs = groupBlogsByYearAndMonth(props.blogs);

    return (
        <div className="history-list">
            <div className="history-container">
                <div style={{ marginBottom: 15, minHeight: 41.5 }}>
                    <h5
                        onClick={() => { props.onYearSelect(null); props.onMonthSelect(null, null); }}
                        className={`show-all-button ${!props.selectedYear && !props.selectedMonth ? 'highlight-selected pointer' : 'pointer'}`}
                    >
                        Show All
                    </h5>
                </div>
                {Object.keys(groupedBlogs).sort((a, b) => b - a).map(year => (
                    <div key={year}>
                        <h5
                            onClick={() => {
                                props.onMonthSelect(null, null);
                                props.onYearSelect(parseInt(year));
                            }}
                            className={props.selectedYear === parseInt(year) && !props.selectedMonth ? 'highlight-selected pointer' : 'pointer'}
                        >
                            {year}
                        </h5>
                        {Object.keys(groupedBlogs[year]).sort().map(month => (
                            <div
                                key={month}
                                onClick={() => props.onMonthSelect(parseInt(year), month)}
                                className={props.selectedYear === parseInt(year) && props.selectedMonth === month ? 'highlight-selected' : ''}
                            >
                                <p>{month} ({groupedBlogs[year][month].length})</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BlogHistory;
