function BlogList(props) {


    return (
        <div className="blog-list-container">
            <div className="blog-list-time">
                {props.selectedYear ?
                    <div>
                        <h5>{props.selectedYear} </h5>
                        {props.selectedMonth &&
                            <div>
                                <h5>&nbsp;-&nbsp;</h5>
                                <h5>{props.selectedMonth}</h5>
                            </div>
                        }
                    </div> :
                    <div>
                        <h5>All</h5>
                    </div>
                }
            </div>
            <div className="blog-list-list">
                {props.blogs && props.blogs.length > 0 &&
                    props.blogs.map((blog) => {
                        return (
                            <div className="blog-list-entry" onClick={() => props.handleSelectBlogToView(blog.slug) }>
                                <div className="blog-list-image">
                                    <img src={blog.image} className="blog-list-image" />
                                </div>
                                <div className="blog-list-content">
                                    <div className="blog-list-entry-title">
                                        <h4>{blog.title}</h4>
                                        <p>{props.formatDate(blog.date)}</p>
                                    </div>
                                    <div className="blog-list-entry-desc">
                                        <p>
                                            {blog.description.length > 200
                                                ? `${blog.description.substring(0, 200)}...`
                                                : blog.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BlogList