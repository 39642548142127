import React from 'react'
import { Image } from 'react-bootstrap';
import Contact from '../../components/home/Contact'

import envelopeIcon from '../../assets/images/envelope-lightW.svg'
import phoneIcon from '../../assets/images/phone-lightW.svg'
import '../../assets/css/landingform.scss'

function LandingForm() {
    return (
        <div className="contactCompContainer2">
            <div className="containerInner2">
                <div className="contactContentContainer2">
                    <div className="contactCompText2">
                        <h3>Elevate Your Retail and Ecommerce Business with Custom Solutions</h3>
                        <p>Discover the power of tailored systems designed exclusively for your retail and ecommerce operations. Contact EJ Software today, and let's embark on a journey to transform your processes, boost efficiency, and achieve unparalleled success. Your business deserves more - choose custom solutions and unlock your full potential.</p>
                    </div>
                    <div className="contactImageContainer2">
                        <div className="contactInfoContainer2">
                            <Image src={envelopeIcon} className="contactIcon" /> <p><a href="mailto: hello@ej-software.co.uk">hello@ej-software.co.uk</a></p>
                        </div>
                        <div className="contactInfoContainer2">
                            <Image src={phoneIcon} className="contactIcon" /> <p>01223 944093</p>
                        </div>
                    </div>
                </div>

                <div className="contactCompForm2">
                    <Contact />
                </div>
            </div>
        </div>
    );
}

export default LandingForm