import React from 'react'
import '../../assets/css/theoutcome.scss'

function TheOutcome() {
    return (
        <div className="outcomeContainer">
            <div className="textContainer">
                <h3>The Challenge</h3>
                <p>Company founders Alex and John knew that providing end users (including Health and Safety managers) with a simple and quick way to check the results of their swab tests - including providing live data - was key to the success of their innovative Hygiene Verification System.</p>
                <p>However, knowing how this would work and look needed external expertise from a software company that not only could develop the digital systems required, but understood how to manage large volumes of sensitive data and present this in the best way.</p>
                <p>In addition, the ideal development partner needed to have the skills and experience to grow with the company and provide ongoing expert guidance and cost-effective solutions as the business scaled.</p>
                <p>After looking at a number of different options, Alex and John turned to EJ Software.</p>
                <h3>The Solution</h3>
                <p>Unlike many of their competitors, EJ Software took the time to fully understand not just the stated requirements for the Hygiene Verification System, but also the innovations created by FreshCheck and the long term ambitions and goals of the business.</p>
                <p>This in-depth discovery process enabled them to quickly present mock-ups of the proposed mobile phone app and web portal that exceeded both co-founder's expectations, as well as put forward a roadmap of developments that would maximise the use of cutting-edge technology (such as AI) whilst helping the company reduce time and costs.</p>
                <p>As the selected software development partner, EJ Software were also happy to take on the role of digital consultants for the business, providing ongoing technical guidance and support, as well as help with funding applications (such as for Innovate UK SMART grants), which FreshCheck has since successfully secured twice to date.</p>
                <h3>The Outcome</h3>
                <p>The web portal and mobile phone app created by EJ Software have become essential elements of the innovative Hygiene Verification System offered by FreshCheck.</p>
                <p>Staff in food production premises, and other environments where cleanliness is a priority, no longer need to use separate handsets to monitor hygiene levels or have to spend their valuable time understanding test results and conducting data analysis.</p>
                <p>Instead, they can instantly generate reports and trend results on their mobile phone or dig even deeper on the dedicated web portal. This enables them to conduct the due diligence required in their industry easier, quicker, and more economically than ever before.</p>
                <p>In addition, offering these digital capabilities has enabled FreshCheck to further differentiate themselves from other hygiene testing businesses and systems, resulting in them spending less time competing with competitors.</p>
            </div>
        </div>
    );
}

export default TheOutcome;