import React from 'react'
import { Image } from 'react-bootstrap';
import Contact from '../home/Contact'

import envelopeIcon from '../../assets/images/envelope-light.svg'
import phoneIcon from '../../assets/images/phone-light.svg'
import '../../assets/css/contactcomp.scss'

function ServiceContactComp(props) {
    return (
        <div className="contactCompContainer">
            <div className="containerInner">
                <div className="contactContentContainer">
                    <div className="contactCompText">
                        <h3>{props.title}</h3>
                        <p>{props.desc}</p>
                    </div>
                    <div className="contactImageContainer">
                        <div className="contactInfoContainer">
                            <Image src={envelopeIcon} className="contactIcon" /> <p><a href="mailto: hello@ej-software.co.uk">hello@ej-software.co.uk</a></p>
                        </div>
                        <div className="contactInfoContainer">
                            <Image src={phoneIcon} className="contactIcon" /> <p>01223 944093</p>
                        </div>
                    </div>
                </div>

                <div className="contactCompForm">
                    <Contact />
                </div>
            </div>
        </div>
    );
}

export default ServiceContactComp