import React from 'react';
import { Row } from 'react-bootstrap';
import Intro from '../components/home/Intro';
import Offering from '../components/home/Offering';
import CaseStudies from '../components/home/CaseStudies';

import WhoWeAre from '../components/home/WhoWeAre';
import Creativity from '../components/home/Creativty'
import MeetTheTeam from '../components/home/MeetTheTeam';

import '../assets/css/home.scss';

function Home() {
    return (
        <>
            <Row className="dark-row" id="home">
                <Intro />
            </Row>
            <Row className="row-center">
                <WhoWeAre />
            </Row>
            <Row className="row-center">
                <Offering />
            </Row>
            <Row className="row-center">
                <Creativity />
            </Row>
            <Row className="row-center">
                <MeetTheTeam />
            </Row>
            <Row className="row-center">
                <CaseStudies />
            </Row>   
        </>
    );
};

export default Home;
