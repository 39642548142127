import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/servicecards.scss'

import serviceIcon1 from "../../assets/images/serviceIcons/-_Consultancy Services.png"
import serviceIcon2 from "../../assets/images/serviceIcons/-_Agile Development.png"
import serviceIcon3 from "../../assets/images/serviceIcons/-_Support and Maintenance.png"


function ServiceCardsComp() {

    return (
        <div className="serviceCardContainer">
            <h2>How EJ Software Can Help</h2>
            <div className="serviceCardHolder">
                <div className="serviceCard">
                    <div className="serviceCardImageDiv">
                        <Image src={serviceIcon1} className="benefitsImage" />
                    </div>
                    <h3>Consultative Approach</h3>
                    <p>At EJ Software, we pride ourselves on a consultative approach that transcends our entire ethos. Our team of experts is dedicated to understanding your business comprehensively. Through in-depth consultations, we delve into your pain points, goals, and aspirations. This ensures that any solution we provide is a perfect match for your unique requirements.</p>
                </div>

                <div className="serviceCard">
                    <div className="serviceCardImageDiv">
                        <Image src={serviceIcon2} className="benefitsImage lolImage" />
                    </div>
                    <h3>Agile Development</h3>
                    <p>Our commitment to excellence extends to our development processes. We adhere to agile development methodologies. This means you are involved at every stage of the process. This iterative approach allows for flexibility, quick adaptations, and ensures that the final product not only meets but exceeds your expectations.</p>
                </div>

                <div className="serviceCard">
                    <div className="serviceCardImageDiv">
                        <Image src={serviceIcon3} className="benefitsImage" />
                    </div>
                    <h3>Ongoing Support and Maintenance</h3>
                    <p>Our dedication doesn't waver after the launch. We believe in the longevity of our solutions. We provide continuous support, updates, and maintenance. This ensures that your systems operate at peak performance, adapting seamlessly to the evolving needs of your business. Our ongoing commitment is to keep your business running smoothly.</p>
                </div>
            </div>
        </div>
    );
}

export default ServiceCardsComp