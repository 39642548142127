import '../../assets/css/meettheteam.scss';
import React from 'react';
import { Image } from 'react-bootstrap'

import EJElliot from '../../assets/images/EjsElliott.png'
import EJJustin from '../../assets/images/EjsJustin.png'
import EJLouis from '../../assets/images/EjsLouis.png'
import EJKatie from '../../assets/images/EjsKatie.png'
import EJElijah from '../../assets/images/EjsElijah.png'
import EJArash from '../../assets/images/EjArash.jpeg'

function MeetTheTeam() {
    return (
        <div className="teamSeg">
            <div className="teamSegOne">
                <h2>Our Team</h2>
                <p>Meet the dynamic team at EJ Software - a group of experienced developers and engineers committed
                    to collaborative, forward-thinking partnerships, making your success our priority.</p>
            </div>
            <div className="teamSegTwo">
                <div className="cardContainer">
                    <Image src={EJElliot} className="headshot" />
                    <div className="personInfo">
                        <p className="personName">Elliott Hicks</p>
                        <p className="personTitle">Co-Founder / CEO</p>
                    </div>
                </div>

                <div className="cardContainer">
                    <Image src={EJJustin} className="headshot" />
                    <div className="personInfo">
                        <p className="personName">Justin Donnelly</p>
                        <p className="personTitle">Co-Founder / CTO</p>
                    </div>
                </div>

            </div>
            <div className="teamSegThree">
                <div className="cardContainer">
                    <Image src={EJKatie} className="headshot" />
                    <div className="personInfo">
                        <p className="personName">Katie Sansoni</p>
                        <p className="personTitle">Project Manager</p>
                    </div>
                </div>

                <div className="cardContainer">
                    <Image src={EJLouis} className="headshot" />
                    <div className="personInfo">
                        <p className="personName">Louis Wright</p>
                        <p className="personTitle">Junior Developer</p>
                    </div>
                </div>


                <div className="cardContainer">
                    <Image src={EJElijah} className="headshot" />
                    <div className="personInfo">
                        <p className="personName">Elijah Dixon</p>
                        <p className="personTitle">Junior Developer</p>
                    </div>
                </div>

                <div className="cardContainer">
                    <Image src={EJArash} className="headshot" />
                    <div className="personInfo">
                        <p className="personName">Arash Abrishami</p>
                        <p className="personTitle">Software Developer</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MeetTheTeam