import React from 'react';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate} from 'react-router-dom'

import '../../assets/css/intro.scss'; 

import Contact from './Contact';
import Typing from './Typing';

function Intro() {

    const navigation = useNavigate()

    const handleNavigateBookIn = () => {
        navigation("/book-in")
    }

    return (
        <div className="segOne">
            <div className="segOneLeft">
                <h2>Bespoke Software, Tailored Solutions,<br />and Lasting Partnerships</h2>
                <p className="less-margin">
                    At EJ Software, we're more than just a team of experts-we're your dedicated partners in achieving seamless, scalable solutions for your business.
                </p>

                <p className="less-margin">
                    Take advantage of our <span className="bold-text">free 60-minute business evaluation</span>: we'll assess your current software, uncover opportunities for automation, and recommend improvements to enhance your operations.
                </p>

                <p className="less-margin">

                    <span className="bold-text">Ready to unlock your business's potential? <span onClick={handleNavigateBookIn} className="pointer-cursor"><u>Book your free evaluation now!</u></span></span>
                </p>

                {/*<div className="centerMe">*/}
                {/*    <Button className="newButton introButton" as={Link} to="/services">Learn More</Button>*/}
                {/*</div>*/}
            </div>
            <div className="segOneRight">
                <Contact />
            </div>
        </div>
    );
};

export default Intro;
