import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import BlogHistory from './BlogHistory';
import BlogList from './BlogList';
import "../../assets/css/blog.scss"
import { useNavigate } from "react-router-dom"
import blogArray from './blogArray';
import moment from "moment"

function BlogContainer() {
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [filteredBlogs, setFilteredBlogs] = useState([]);

    const navigation = useNavigate()

    useEffect(() => {
        const filtered = blogArray
            .filter(blog => {
                const blogDate = new Date(blog.date);
                const blogYear = blogDate.getFullYear();
                const blogMonth = blogDate.toLocaleString('default', { month: 'long' });

                if (selectedYear && selectedMonth) {
                    return blogYear === selectedYear && blogMonth === selectedMonth;
                } else if (selectedYear) {
                    return blogYear === selectedYear;
                }
                return true;
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order

        setFilteredBlogs(filtered);
    }, [selectedYear, selectedMonth]);


    const handleSelectBlogToView = (slug) => {
        navigation(`/blog/${slug}`)
    }

    const formatDate = (dateString) => {
        return moment(dateString).format('dddd Do MMMM YYYY');
    };

    return (
        <div className="blog-container pt-5">
            <Col>
                <Row>
                    <Col sm="3">
                        <BlogHistory
                            blogs={blogArray}
                            onYearSelect={setSelectedYear}
                            onMonthSelect={(year, month) => {
                                setSelectedYear(year);
                                setSelectedMonth(month);
                            }}
                            selectedYear={selectedYear}
                            selectedMonth={selectedMonth}
                        />
                    </Col>
                    <Col>
                        <BlogList
                            blogs={filteredBlogs}
                            selectedYear={selectedYear}
                            selectedMonth={selectedMonth}
                            handleSelectBlogToView={handleSelectBlogToView}
                            formatDate={formatDate}
                        />
                    </Col>
                </Row>
            </Col>
        </div>
    );
}

export default BlogContainer;
