import React from 'react';
import { Row } from 'react-bootstrap';

import ServiceContactComp from '../components/servicesComponents/ServiceContactComp'
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/ai.jpg'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Predictive Analytics.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Personalized Experiences.png"
import serviceIcon3 from "../assets/images/serviceIcons/serviceIconBrain.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'


function AIMachineLearning() {

    const benefitsArray = [
        {
            title: "Predictive Analytics",
            desc: "Anticipate trends and make informed decisions. Our AI & Machine Learning solutions analyze historical data to provide predictive insights, helping you stay ahead of the competition and adapt to changing market dynamics.",
            cardImage: serviceIcon1
        },
        {
            title: "Personalized Experiences",
            desc: "Deliver tailored experiences to your customers. Our solutions utilize AI to understand customer preferences, predict behavior, and personalize interactions, fostering stronger, long-lasting relationships.",
            cardImage: serviceIcon2
        },
        {
            title: "Continuous Learning",
            desc: "Stay at the forefront of innovation. Our AI & Machine Learning solutions are designed for continuous learning, adapting to new data and evolving business requirements to ensure sustained growth.",
            cardImage: serviceIcon3
        },
    ]

    return (
        <div>
            <Row className="row-center">
                <ServiceDescComp pageTitle="Intelligent Operations: Harness the Power of AI & Machine Learning"
                    subDesc="At EJ Software, we're at the forefront of innovation, introducing AI & Machine Learning solutions to elevate your business to unprecedented heights."
                    serviceTitle="What is AI & Machine Learning?"
                    desc="Artificial Intelligence (AI) and Machine Learning (ML) empower machines to learn from data and make intelligent decisions. Our solutions harness this technology to automate processes, gain insights, and drive innovation within your business."
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp desc1={`"AI and machine learning aren't just technologies; they are catalysts for business evolution. By harnessing the predictive power of data, these solutions empower businesses to foresee trends, personalize experiences, and pioneer a future where intelligent insights drive not just efficiency, but unparalleled innovation."`} />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing AI & Machine learning" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
            <Row className="row-center">
                <ServiceContactComp title="Transform your operations with the power of intelligence"
                    desc="Contact EJ Software today to explore how AI & Machine Learning can revolutionize your business." />
            </Row>
        </div>
    );
}

export default AIMachineLearning