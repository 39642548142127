import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/feedback.scss'

import goddardsFeedbackImage from '../../assets/images/goddardsFeedbackImage.png'

function Feedback() {
    return (
        <div className="feedbackContainer">
            <div className="contentBox">
                <div className="fcFounderBox">
                    <Image src={goddardsFeedbackImage} className="fcFounderImage" />
                    {/*<p>FreshCheck co-founders Alex Bond (l) and John Simpson (r)</p>*/}
                </div>
                <h3>Client Feedback</h3>
                <p>Head of Merchandising Kirsty Grief said: <span className="italicText">"EJ Software have been a pleasure to work with and from the very start it was clear they had great ideas and a real can-do attitude.</span></p>
                <p className="italicText">"Nothing is too much trouble for them and they are always very easy to talk to, offering different perspectives and suggesting great solutions that help us grow the business, make life easier for staff, and save costs.</p>
                <p className="italicText">"The Orange Square inventory management system they have built for us has been very positively received across the business, making stock control tasks far quicker and simpler for store staff whilst enabling us to have complete visibility of inventory around the Group.</p>
                <p className="italicText">"And we now have a system that can grow with us and can become whatever we need it to be, presenting exciting opportunities for the future as we look at other activities in the business.</p>
                <p className="italicText">"We are delighted with our relationship with them and look forward to continuing to work with them as we grow the business.</p>
                <p className="italicText">"EJ Software are now our go-to company for any software development and we would happily recommend them to any other company looking for a bespoke solution that fully meets their individual business needs."</p>
            </div>
        </div>
    );
}

export default Feedback;