import React from 'react';
import { Row } from 'react-bootstrap';
import Discovery from '../components/RetailLanding/Discovery';
import LandingForm from '../components/RetailLanding/LandingForm';
import LandingExtra from "../components/RetailLanding/LandingExtra"

//Retail section
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/retail.jpg'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Unified Retail- Management.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Efficient -Inventory Control.png"
import serviceIcon3 from "../assets/images/serviceIcons/-_Secure Payment- Processing.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'

function RetailLanding() {

    const benefitsArray = [
        {
            title: "Unified Retail Management",
            desc: "Effortlessly integrate your online and offline operations for a seamless retail experience. Our solutions unify your management processes, providing real-time insights and ensuring a consistent customer experience across all channels.",
            cardImage: serviceIcon1
        },
        {
            title: "Efficient Inventory Control",
            desc: "Optimize your supply chain with our inventory management systems. Track stock levels, manage orders, and prevent stockouts, ensuring that you meet customer demand without unnecessary delays.",
            cardImage: serviceIcon2
        },
        {
            title: "Secure Payment Processing",
            desc: "Provide your customers with a secure and efficient checkout experience. Our Stripe integration allows for flexible payment options, from credit cards to digital wallets, catering to diverse customer preferences.",
            cardImage: serviceIcon3
        },
    ]

    return (
        <div>
            <Row className="row-center">
                <Discovery />
            </Row>
            <Row className="row-center">
                <LandingForm />
            </Row>
            <Row className="row-center">
                <LandingExtra />
            </Row>
            {/*Retail Section*/}
            <Row className="row-center">
                <ServiceDescComp pageTitle="Seamless Commerce: Elevate Your Retail & Ecommerce Experience"
                    subDesc="At EJ Software, we recognize the dynamic world of retail and ecommerce. Introducing our Retail & Ecommerce Solutions - a comprehensive suite designed to revolutionize your business operations and enhance your online presence."
                    serviceTitle="What are Retail & Ecommerce Solutions?"
                    desc="Our Retail & Ecommerce Solutions are tailored to meet the unique challenges of modern commerce. From advanced Point of Sale (POS) systems to seamless Shopify integration, robust inventory management, and secure payment processing with Stripe, we provide a holistic solution for your retail needs"
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp title="Unleash the Power of Customization"
                    subTitle1="Tailored to Your Business"
                    desc1="No two businesses are the same, and neither should their CRMs be. Our Bespoke CRM services ensure that every feature, module, and integration is customized to fit seamlessly into your existing processes, empowering you to manage your customer relationships with unparalleled efficiency."
                    subTitle2="Scalability at Your Fingertips"
                    desc2="As your business evolves, so should your CRM. With our Bespoke CRM solutions, scalability is never an issue. Whether you're a growing startup or an established enterprise, our systems can adapt and scale to match the pace of your expansion."
                />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing Retail & Ecommerce Solutions" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
        </div>
    )
}

export default RetailLanding