import React from "react"

function Calendly() {
    return (
        <div style={{ height: "700px", width: "700px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <iframe src="https://calendly.com/justin_donnelly" title="Calendly" width="100%" height="100%">

            </iframe>
        </div>
    )
}

export default Calendly