import { Row } from 'react-bootstrap';
import BlogView from '../components/blog/BlogView';
import { useParams } from "react-router-dom"
import blogArray from '../components/blog/blogArray';
import { useEffect, useState } from 'react';
import "../assets/css/blogView.scss"
function BlogEntry() {

    const { article } = useParams()
    const [selectedBlog, setSelectedBlog] = useState()

    useEffect(() => {
        // Reset selectedBlog to trigger loading state or blank slate before new content loads
        setSelectedBlog(undefined);

        if (article) {
            const foundBlog = blogArray.find((b) => b.slug === article);
            setSelectedBlog(foundBlog);
        }
    }, [article]);

    return (
        <div>
            <Row className="row-center">
                {selectedBlog ? <BlogView selectedBlog={selectedBlog} /> : null }
            </Row>
        </div>
    )
}

export default BlogEntry