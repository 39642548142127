import React from 'react';
import { Row } from 'react-bootstrap';

import ServiceContactComp from '../components/servicesComponents/ServiceContactComp'
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/techImage.png'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Enhanced Productivity.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Data-driven- Decision Making.png"
import serviceIcon3 from "../assets/images/serviceIcons/-_Improved- Customer Experience.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'


function BespokeCRM() {

    const benefitsArray = [
        {
            title: "Enhanced Productivity",
            desc: "Efficiency is the key to success. Our Bespoke CRMs are designed to optimize your workflows, automate repetitive tasks, and provide your team with the tools they need to excel. Say goodbye to manual data entry and hello to a streamlined, productive workforce.",
            cardImage: serviceIcon1
        },
        {
            title: "Data-driven Decision Making",
            desc: "Make informed decisions backed by powerful insights. Our Bespoke CRMs enable you to gather, analyze, and visualize data in real-time. Identify trends, track performance, and gain valuable insights to drive your business forward.",
            cardImage: serviceIcon2
        },
        {
            title: "Improved Customer Experience",
            desc: "Deliver personalized experiences that leave a lasting impression. Our Bespoke CRMs empower you to understand your customers on a deeper level, anticipate their needs, and provide unparalleled service that fosters long-term relationships.",
            cardImage: serviceIcon3
        },
    ]


    return (
        <div>
            <Row className="row-center">
                <ServiceDescComp pageTitle="Tailored Efficiency: Unleash Your Potential with Bespoke CRMs"
                    subDesc="At EJ Software, we understand that your business is unique, and so are your needs. That's why we offer Bespoke CRMs - a cutting-edge solution designed to streamline your operations and elevate your business to new heights."
                    serviceTitle="What is a Bespoke CRM?"
                    desc="A Bespoke CRM (Customer Relationship Management) system is a tailor-made software solution crafted specifically for your business. Unlike off-the-shelf CRM solutions that offer a one-size-fits-all approach, our Bespoke CRMs are meticulously designed to align with your organizational goals, workflows, and distinct requirements."
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp desc1={`"Bespoke CRMs redefine the way businesses manage customer relationships. Tailored to your unique needs, these customized solutions are crafted to seamlessly integrate into your processes, unlocking unparalleled efficiency. With bespoke CRMs, you're not just managing relationships; you're orchestrating a symphony of personalized interactions that elevate your business to new heights."`} />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing Bespoke CRMs" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
            <Row className="row-center">
                <ServiceContactComp title="Elevate Your Business with Bespoke CRMs"
                    desc="Discover the power of a CRM system designed exclusively for your business. Contact EJ Software today, and let's embark on a journey to transform your operations, boost efficiency, and achieve unparalleled success. Your business deserves more - choose Bespoke CRMs and unlock your full potential."/>
            </Row>
        </div>
    );
}

export default BespokeCRM