import React from 'react';
import '../../assets/css/resources.scss';
import { Image, Button } from 'react-bootstrap';
import daBoyz from '../../assets/images/daBoyz.jpg';
import { Link } from "react-router-dom";

function ResourceDesc(props) {
    return (
        <div className="segTwo">
            <div className="segTwoLeft">
                <Image src={daBoyz} className="daBoyzWorking" />
            </div>
            {props.resData ? (
                <div className="segTwoRight">
                    <h2>{props.resData.title}</h2>
                    <p>{props.resData.para1}</p>
                    <p>{props.resData.para2}</p>
                    <Button className="newButton">Download</Button>
                </div>
            ) : (
                <div className="segTwoRight">
                    <h2>This is where I'd put resources, if I had any</h2>
                    <p>Placeholder text fejsnfsefn esfkjnf sefkjn se fkjsnf sefjnse fskjefn</p>
                    <p>fsekjf ek fjnsf hfbsehjfbse jhkgnrdkjgsefhjbse jkesnfnfse ek snjfsef jnkesf njkdawd</p>
                    <div className="links-container">
                        {props.resLinks && props.resLinks.length > 0 &&
                            props.resLinks.map((link, index) => (
                                <Button key={index} className="newButton" as={Link} to={`/resources/${link.link}`}>
                                    {link.title}
                                </Button>
                            ))
                        }
                    </div>
                </div>
            )}
        </div>
    );
}

export default ResourceDesc;
