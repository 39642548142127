import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/feedback.scss'

import FreshFounder from '../../assets/images/case-study/fc-founders.jpg'

function Feedback() {
    return (
        <div className="feedbackContainer">
            <div className="contentBox">
                <div className="fcFounderBox">
                    <Image src={FreshFounder} className="fcFounderImage" />
                    <p>FreshCheck co-founders Alex Bond (l) and John Simpson (r)</p>
                </div>
                <h3>Client Feedback</h3>
                <p>Co-founder Alex Bond: <span className="italicText">"From the very start, EJ Software have proven to be a great partner for FreshCheck. They got to know us quickly, really understood our business and our ambitions, and have created digital solutions for us that are critical elements of our Hygiene Verification System package.</span></p>
                <p className="italicText">"What's more, they have kept improving and adding to the app and web portal and continually come up with great ideas on how we can make the service even better for our customers. And we have always found them to be very responsive, positive, and ready to go the extra mile.</p>
                <p className="italicText">"We 100% recommend them."</p>
                <p>Fellow co-founder John Simpson added: <span className="italicText">"As well as delivering digital solutions that exceed our expectations, EJ Software's ongoing support for our business and our funding applications has been invaluable.</span></p>
                <p className="italicText">"And they always explain things clearly, are upfront about their costs, and challenge us when needed which helps us see things in different ways.</p>
                <p className="italicText">"We are delighted with our relationship with them and look forward to continuing to work with them as we grow the business."</p>
            </div>
        </div>
    );
}

export default Feedback;