import React from 'react';
import { Row } from 'react-bootstrap';

import ServiceContactComp from '../components/servicesComponents/ServiceContactComp'
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/iot.jpg'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Smart Asset- Management.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Predictive Maintenance.png"
import serviceIcon3 from "../assets/images/serviceIcons/-_Data-Driven Insights.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'


function IoT() {

    const benefitsArray = [
        {
            title: "Smart Asset Management",
            desc: "Efficiently monitor and manage your assets in real-time. Our IoT solutions provide insights into the performance and usage of your assets, helping you make data-driven decisions to optimize your operations.",
            cardImage: serviceIcon1
        },
        {
            title: "Predictive Maintenance",
            desc: "Say goodbye to unplanned downtime. Our IoT solutions utilize predictive analytics to foresee potential issues, allowing for proactive maintenance and minimizing disruptions to your business.",
            cardImage: serviceIcon2
        },
        {
            title: "Data-Driven Insights",
            desc: "Unlock the potential of your data. Our IoT solutions gather, analyze, and visualize data in real-time, providing you with actionable insights to drive informed decision-making and strategy development.",
            cardImage: serviceIcon3
        },
    ]

    return (
        <div>
            <Row className="row-center">
                <ServiceDescComp pageTitle="The Era of Connectivity: Unleash the Power of IoT"
                    subDesc="We're ushering businesses into the future with our IoT solutions - a transformative approach to connectivity and data-driven decision-making."
                    serviceTitle="What is IoT?"
                    desc="The Internet of Things (IoT) is a network of interconnected devices that communicate and share data seamlessly. Our IoT solutions leverage this network to enhance operational efficiency, improve decision-making, and create new possibilities for your business."
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp desc1={`"The true power of IoT lies in its ability to transform data into actionable insights, enabling businesses to make informed decisions in real-time, optimize processes, and create a more connected and efficient world."`} />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing IoT" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
            <Row className="row-center">
                <ServiceContactComp title="Step into a connected future"
                    desc="Contact us today to explore how IoT can revolutionize your business operations and open new avenues for growth." />
            </Row>
        </div>
    );
}

export default IoT