import React from 'react';
import { Row } from 'react-bootstrap';

import ServiceContactComp from '../components/servicesComponents/ServiceContactComp'
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/retail.jpg'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Unified Retail- Management.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Efficient -Inventory Control.png"
import serviceIcon3 from "../assets/images/serviceIcons/-_Secure Payment- Processing.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'


function RetailEcommerce() {

    const benefitsArray = [
        {
            title: "Unified Retail Management",
            desc: "Effortlessly integrate your online and offline operations for a seamless retail experience. Our solutions unify your management processes, providing real-time insights and ensuring a consistent customer experience across all channels.",
            cardImage: serviceIcon1
        },
        {
            title: "Efficient Inventory Control",
            desc: "Optimize your supply chain with our inventory management systems. Track stock levels, manage orders, and prevent stockouts, ensuring that you meet customer demand without unnecessary delays.",
            cardImage: serviceIcon2
        },
        {
            title: "Secure Payment Processing",
            desc: "Provide your customers with a secure and efficient checkout experience. Our Stripe integration allows for flexible payment options, from credit cards to digital wallets, catering to diverse customer preferences.",
            cardImage: serviceIcon3
        },
    ]


    return (
        <div>
            <Row className="row-center">
                <ServiceDescComp pageTitle="Seamless Commerce: Elevate Your Retail & Ecommerce Experience"
                    subDesc="At EJ Software, we recognize the dynamic world of retail and ecommerce. Introducing our Retail & Ecommerce Solutions - a comprehensive suite designed to revolutionize your business operations and enhance your online presence."
                    serviceTitle="What are Retail & Ecommerce Solutions?"
                    desc="Our Retail & Ecommerce Solutions are tailored to meet the unique challenges of modern commerce. From advanced Point of Sale (POS) systems to seamless Shopify integration, robust inventory management, and secure payment processing with Stripe, we provide a holistic solution for your retail needs"
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp desc1={`"In the realm of retail and e-commerce, embracing IoT solutions is more than innovation-it\'s the key to unlocking seamless customer experiences, optimizing operations, and staying ahead in a dynamic market. Embrace the future, where every connection brings new possibilities for growth and success."`} />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing Retail & Ecommerce Solutions" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
            <Row className="row-center">
                <ServiceContactComp title="Elevate your retail game"
                    desc="Contact us today to transform your business into a seamless commerce powerhouse." />
            </Row>
        </div>
    );
}

export default RetailEcommerce