//Pages (Grouped by page and preview image)
import EssentialSoftwareScalingBusiness from "./blogs/EssentialSoftwareScalingBusiness"
import EssentialSoftwareScalingBusinessImage from "../../assets/images/EssentialSoftwareScalingBusiness.png"

import TheConvergenceOfEdgeComputingAndIoT from "./blogs/TheConvergenceOfEdgeComputingAndIoT"
import IoTImage from "../../assets/images/IoTImage.png"

import BuildingAGreenerFutureWithCustomSoftwareSolutions from "./blogs/BuildingAGreenerFutureWithCustomSoftwareSolutions"
import GreenerImage from "../../assets/images/GreenerImage.png"

import HowCustomSoftwareSolutionsDriveEfficiencyAndCutCost from "./blogs/HowCustomSoftwareSolutionsDriveEfficiencyAndCutCost"
import CutCostImage from "../../assets/images/CutCostImage.png"

import NavigatingCybersecurityThreatsInSoftwareDevelopment from "./blogs/NavigatingCybersecurityThreatsInSoftwareDevelopment" 
import CyberSecurityImage from "../../assets/images/CyberSecurityImage.png"


const blogArray = [
    {
        date: "2024-09-30T14:00:33.277Z",
        image: EssentialSoftwareScalingBusinessImage,
        title: "Essential Software Tools Every Business Needs to Scale",
        slug: "essential-software-scaling-business",
        description: "Starting a business is hard. For lots of people it's the hardest thing they will ever do, but luckily in the age of modern technology we have some tech that helps us all out. At EJ Software we build bespoke software that unifies lots of business needs however we recognise the need to use existing software whilst your business grows. Below are some areas that you might not be using software for yet that have the potential to make your business more scalable.",
        page: <EssentialSoftwareScalingBusiness />
    },
    {
        date: "2024-10-07T14:00:33.277Z",
        image: IoTImage,
        title: "The Convergence of Edge Computing and IoT",
        slug: "edge-computing-internet-of-thing",
        description: "In the realm of technology, the only constant is change. One of the most transformative shifts in recent years is the convergence of Edge Computing and the Internet of Things (IoT). This evolution is reshaping how software applications are developed and deployed. Here, we�ll break down the concept of Edge Computing and its growing impact on software development.",
        page: <TheConvergenceOfEdgeComputingAndIoT />
    },
    {
        date: "2024-10-14T14:00:33.277Z",
        image: GreenerImage,
        title: "Building a Greener Future with Custom Software Solutions",
        slug: "greener-custom-software",
        description: "Efficient businesses are inherently more sustainable. By prioritising efficiency businesses are maximising use of their resources, reducing waste and wasted time, money and energy. By streamlining processes, minimising errors and automating repetitive tasks they consume fewer resources supporting efforts towards more eco friendly practices. This is where bespoke software becomes a solution to help uphold sustainable business practices.",
        page: <BuildingAGreenerFutureWithCustomSoftwareSolutions />
    },
    {
        date: "2024-10-21T14:00:33.277Z",
        image: CutCostImage,
        title: "How Custom Software Solutions Drive Efficiency and Cut Costs",
        slug: "why-custom-software",
        description: "Custom software is an investment. It costs time and money and (when done well) requires attention to detail. At a glance many business owners may write it off as too complex to deal with and not a priority but this often shows a lack of understanding about the long term benefits of bespoke solutions. We are obviously biassed, EJ Software is a bespoke software company so naturally we think that every company should be considering bespoke software but below we are going to explain a lot of concepts we explain repeatedly to people who have never built custom software within a business before.",
        page: <HowCustomSoftwareSolutionsDriveEfficiencyAndCutCost />
    },
    {
        date: "2024-10-28T14:00:33.277Z",
        image: CyberSecurityImage,
        title: "Navigating Cybersecurity Threats in Software Development",
        slug: "cybersecurity-software-developmen",
        description: "In today's increasingly digital world, the importance of cybersecurity is more critical than ever. As a business owner you might cringe at the thought of cyber security. Often we speak to founders who know they should be doing more but just don�t know where to start. Software development has always been about innovation and problem-solving, but alongside these advancements comes the responsibility of safeguarding data, privacy, and digital assets. Cybersecurity threats have evolved from being occasional disruptions to significant, often devastating challenges for businesses. From data breaches to ransomware attacks, the digital frontier is increasingly under siege, making robust cybersecurity strategies a necessity for every organisation.",
        page: <NavigatingCybersecurityThreatsInSoftwareDevelopment />
    }
]

export default blogArray