import React from 'react';
import { Row } from 'react-bootstrap';

import CompanyDesc from '../components/FreshCheck/CompanyDesc';
import TheOutcome from '../components/FreshCheck/TheOutcome';
import Feedback from '../components/FreshCheck/Feedback';
import AboutEj from '../components/FreshCheck/AboutEj';
import CaseStudies from '../components/home/CaseStudies';
import '../assets/css/casestudy.scss';

import { useMediaQuery } from 'react-responsive';



function FreshCheck() {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' })

    return (
        <div>
            <Row className="row-center">
                <CompanyDesc />
            </Row>
            <Row className="row-center">
                <TheOutcome />
            </Row>
            <Row className="row-center">
                <Feedback />
            </Row>
            <Row className="row-center">
                <AboutEj />
            </Row>
            <Row className="row-center extraPad">
                <CaseStudies />
            </Row>
        </div>
            
    );
};

export default FreshCheck;
