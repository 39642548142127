import { useNavigate } from "react-router-dom"
import blogArray from "./blogArray"

function BlogView(props) {

    const navigation = useNavigate()

    const handleBackToBlog = () => {
        navigation(`/blog`)
    }

    const handleNavigateToSelectedBlog = (slug) => {
        navigation(`/blog/${slug}`)
    }

    const getSuggestedBlogs = () => {
        const filteredBlogs = blogArray.filter(blog => blog.slug !== props.selectedBlog.slug)
        const shuffledBlogs = filteredBlogs.sort(() => 0.5 - Math.random())
        return shuffledBlogs.slice(0, 4)
    }

    const suggestedBlogs = getSuggestedBlogs()

    return (
        <div>
            <div className="add-pointer mb-4">
                <i className="fa fa-arrow-left" onClick={handleBackToBlog} />
            </div>
            {props.selectedBlog ? (
                props.selectedBlog.page
            ) : (
                <p>Blog page not found.</p>
            )}
            <div className="recommended-blog-container pb-4 mt-5">
                <h3>
                    Suggested Blogs
                </h3>
                <div className="recommended-blogs">
                    {suggestedBlogs.map((blog, index) => (
                        <div key={index} className="recommended-blog-item" onClick={() => handleNavigateToSelectedBlog(blog.slug) }>
                            <img src={blog.image} alt={blog.title} />
                            <h4>{blog.title}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BlogView;
