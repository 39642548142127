import React from 'react';
import '../assets/css/home.scss';
import Calendly from '../components/utilityComps/Calendly';

function BookIn() {
    return (
        <div className="segOne">
            <div className="segOneLeft">
                <h2 className="altTitle">Unlock the potential of bespoke software solutions with EJ Software</h2>
                <p style={{marginBottom: 30} }>We're not just a team of experts; we're your dedicated partners in bringing your software visions to life. Whether you're dreaming of a streamlined internal system or a powerful, scalable platform to drive your business forward, we've got you covered.
                </p>
                <p>Ready to take the next step? Book in for a free discovery call with us and let's explore how we can elevatate your software journey together.</p>
            </div>
            <div className="segOneRight">
                <Calendly />
            </div>
        </div>
    );
};

export default BookIn;
