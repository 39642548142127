import { Image } from 'react-bootstrap'
import React from 'react'
import '../../assets/css/creativity.scss'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'

import drDuck from '../../assets/images/drDuck.jpg'

function Creativity() {
    return (
        <div className="segThree">
            <div className="segThreeLeft">
                <h2>Why Us? Because Software
                    Development Can Be Fun,<br />
                    Seriously!</h2>
                <p>At EJ Software, we're not just about code; we're about building
                    relationships, cracking jokes (occasionally good ones), and
                    crafting software that's as unique as your business.
                </p>
                <p>Here's why we're the software wizards you've been looking for:</p>
                <ul>
                    <li><span>Long-term Love Affairs</span></li>
                    <li><span>Tailor-Made Marvel</span></li>
                    <li><span>Innovation Nation</span></li>
                    <li><span>Quality Quotient</span></li>
                    <li><span>Support That Never Quits</span></li>
                </ul>
                <p>Software development doesn't have to be all serious business.
                    We're here to make it a journey filled with laughter, innovation,
                    and a touch of magic (or code, but close enough). Oh, and just
                    between us, we're all a bit quackers, and we think that's what
                    makes the journey even more delightful.</p>
                <p>Let's build something awesome together!</p>
                <Button className="newButton" as={Link} to="/services">Learn More</Button>
            </div>
            <div className="segThreeRight">
                <div className="magicBorder"></div>
                <Image src={drDuck} className="drDuck"/>
            </div>
        </div>
    );
}

export default Creativity