import EssentialSoftwareScalingBusinessImage1 from "../../../assets/images/EssentialSoftwareScalingBusiness.png"
import EssentialSoftwareScalingBusinessImage2 from "../../../assets/images/EssentialSoftwareScalingBusinessImage2.png"
import EssentialSoftwareScalingBusinessImage3 from "../../../assets/images/EssentialSoftwareScalingBusinessImage3.png"

const EssentialSoftwareScalingBusiness = () => {
    return (
        <div className="blog-container-page">
            <div className="blog-content-image">
                <img src={EssentialSoftwareScalingBusinessImage1}/>
            </div>
            <div>
                <h3>Essential Software Tools Every Business Needs to Scale </h3>
                <p>
                    Starting a business is hard. For lots of people its the hardest thing they will ever do, but luckily in the age of modern technology we have some tech that helps us all out. At <a href="https://www.ej-software.co.uk/" target="_blank" rel="noreferrer">EJ Software</a> we build bespoke software that unifies lots of business needs however we recognise the need to use existing software whilst your business grows. Below are some areas that you might not be using software for yet that have the potential to make your business more scalable.
                </p>
            </div>

            <div>
                <h3>Software for Project Management</h3>
                <p>Especially if your business is in Service, keeping track of where you are on multiple things at once is a difficult task. This is where some form of Project management software goes from useful to life saving. As your business grows, you don't want to spend all your time emailing people for updates on where you are, one central system that tracks all this for you becomes indispensable.  </p>
                <p>Some existing tools for this include: </p>
                <ul>
                    <li><a href="https://trello.com/" target="_blank" rel="noreferrer">Trello</a></li>
                    <li><a href="https://asana.com/" target="_blank" rel="noreferrer">Asana</a></li>
                    <li><a href="https://www.proofhub.com/" target="_blank" rel="noreferrer">Proofhub</a></li>
                </ul>
                <p>Often businesses outgrow these platforms over time but they are great for growing in the interim. When you find that these systems are no longer doing what you need them to do, that's when to start exploring your options for something bespoke to your delivery.</p>
            </div>
            <div className="blog-content-image">
                <img src={EssentialSoftwareScalingBusinessImage2} />
            </div>
            <div>
                <h3>Software for Accounting</h3>
                <p> Cashflow and finances are the backbone of all scaling companies, so it's best to get in the habit of tracking all your finances ASAP. If you have already got an accountant it's worth asking their advice on software that might make your life easier. Many will recommend something like <a href="https://quickbooks.intuit.com/uk/" target="_blank" rel="noreferrer">Quickbooks</a>.</p>
                <p>Within Bespoke Software we have some very cool integrations to make your financial life easier. We can set up things like automatic invoices, cashflow dashboards and make sure your financial data is displayed in a way your whole team can understand.</p>
            </div>
            <div>
                <h3>Software for Communication</h3>
                <p>As a team grows you need more and more collaboration and to facilitate that you will likely need some form of communication channel beyond email.</p>
                <p>A couple of the most popular softwares for this include:</p>
                <ul>
                    <li><a href="https://slack.com/intl/en-gb" target="_blank" rel="noreferrer">Slack</a></li>
                    <li><a href="https://teams.microsoft.com/v2/" target="_blank" rel="noreferrer">Microsoft Teams</a></li>
                    <li><a href="https://zoom.us/" target="_blank" rel="noreferrer">Zoom</a></li>
                </ul>
                <p>API and Software integrations mean that you can trigger automated messages in these providers when certain actions are performed. Meaning less time tracking and more time doing what matters.</p>
            </div>
            <div className="blog-content-image">
                <img src={EssentialSoftwareScalingBusinessImage3} />
            </div>
            <div>
                <h3>Software for Sales and Marketing</h3>
                <p>Acquiring new clients and customers is a huge factor in scaling up. So software that tracks your Sales and Marketing efforts are vital for ensuring that cash flow we mentioned earlier.</p>
                <p>Popular providers of this software include:</p>
                <ul>
                    <li><a href="https://www.salesforce.com/uk/" target="_blank" rel="noreferrer">Sales Force</a></li>
                    <li><a href="https://www.hubspot.com/" target="_blank" rel="noreferrer">Hubspot</a></li>
                </ul>
                <p>A reason why these should be managed by software rather than just kept in someone's brain is because this is an area of business very susceptible to individual bias. Marketing efforts are often over emphasised by owners and so having the data behind this helps you scale your efforts based on logic rather than gut feelings.</p>
            </div>
            <div>
                <h3>When to use Bespoke Software Solutions</h3>
                <p>We think a good indicator that it's time to switch to something bespoke is when you have all the interim softwares in place. At this point it makes sense purely financially. We often speak to companies who are paying thousands in subscription costs and are spending a significant amount of time helping these existing softwares speak to eachother (eg: transferring clients or customers from their marketing software, to their client management software, comparing that to their accounting software). At this point the investment of services that are fully built to your business are a bit of a no brainer. When you reach that point it's definitely worth having a quote from us at EJ Software to see what you can save.</p>
            </div>
        </div>
    )
}

export default EssentialSoftwareScalingBusiness