import React, { useState } from 'react'; // Don't forget to import useState
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row } from 'react-bootstrap';
import '../../assets/css/contact.scss';

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    message: Yup.string().min(20, 'Message must be 20 or more characters'),
});

function Contact() {
    const [messageSent, setMessageSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    // Move the MyForm component outside of the Contact function
    const MyForm = () => {
        const formik = useFormik({
            initialValues: {
                name: '',
                email: '',
                message: '',
            },
            validationSchema: validationSchema,
            onSubmit: async values => { // Add async keyword to onSubmit function
                try {
                    const contactUs = {
                        name: values.name,
                        email: values.email,
                        message: values.message
                    };

                    let url = `ContactUs/Contact`;
                    const response = await fetch(url, { // Remove unnecessary parentheses around 'url'
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json", // Use an object for headers
                        },
                        body: JSON.stringify(contactUs),
                    });
                    const body = await response.json();
                    if (body === true) {
                        setMessageSent(true);
                        setSendError(false); // Reset the error state
                    } else {
                        setSendError(true);
                        setMessageSent(false); // Reset the success state
                    }
                } catch (err) {
                    setSendError(true); // Handle fetch errors by setting error state
                    setMessageSent(false); // Reset the success state
                }
            },
        });

        return (
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="contact-error">{formik.errors.name}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="email" className="">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="contact-error">{formik.errors.email}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                    />
                    {formik.touched.message && formik.errors.message ? (
                        <div className="contact-error">{formik.errors.message}</div>
                    ) : null}
                </div>
                <center>
                    <button className="submitButton" type="submit">Send</button>
                    <p className="formTerms">By submitting this form, you agree to our <a href="/privacy">Privacy Policy</a>.</p>
                </center>
            </form>
        );
    };

    return (
        <>
            {!messageSent &&
                <Row className="contact-form">
                    {!messageSent && (
                        <>
                            <h3>Ready to start your software journey?</h3>
                            <h3 class="readForm">Simply fill in the form below, and we'll reach out to continue the conversation.</h3>
                            <MyForm />
                        </>
                    )}
                    {sendError && (
                        <>
                            <h5>There was an error sending your message. If this persists, please send an email to  <a href="mailto:hello@ej-software.co.uk">hello@ej-software.co.uk</a></h5>
                        </>
                    )}
                </Row>
            }
            {messageSent && (
                <div className="contacted">
                    <h3>Thanks for contacting us. We will get back to you shortly to start helping you create the software your company needs.</h3>
                </div>
            )}
        </>
    );
}

export default Contact;

