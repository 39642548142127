import React, { useEffect, useState } from 'react';
import { Container, Navbar, Image, NavDropdown, Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import mailIcon from '../../assets/images/envelope-light.svg'
import phoneIcon from '../../assets/images/phone-light.svg'
import linkedinicon from '../../assets/images/linkedinIcon.svg'
import facebookicon from '../../assets/images/facebookIcon.svg'
import instagramicon from '../../assets/images/instagramIcon.svg'
import xicon from '../../assets/images/xIcon.svg'
function NavMenu() {

    const [expanded, setExpanded] = useState(false)
    const [dropdownOpenService, setDropdownOpenService] = useState(false)
    const [dropdownOpenCaseStudy, setDropdownOpenCaseStudy] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest('.burgerHolder')) {
                setExpanded(false)
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    const handleOpen = () => {
        if (expanded === true) {
            setExpanded(false)
        } else {
            setExpanded(true)
        }
    }

    const toggleDropdownService = () => {
        if (dropdownOpenService === false) {
            setDropdownOpenService(true)
        } else {
            setDropdownOpenService(false)
        }
    }

    const toggleDropdownCaseStudy = () => {
        if (dropdownOpenCaseStudy === false) {
            setDropdownOpenCaseStudy(true)
        } else {
            setDropdownOpenCaseStudy(false)
        }
    }

    const navigateToServices = (e) => {
        if (e.target.closest('.dropdown-toggle')) {
            navigate('/services')
        } 
    }

    const navigateToCaseStudy = (e) => {
        if (e.target.closest('.dropdown-toggle')) {
            navigate('/case-study')
        }
    }

    return (
        <header>
            <Navbar expand="sm" className="navbar" expanded={expanded}>
                {/*<Navbar.Brand href="/" className="p-0">*/}
                <div className="containerTest">
                    <div className="contactInfo">
                        <Link className="mobileImageDiv" to="/">   
                                <Image src={logo} className="mobileImage" />                           
                        </Link>
                        <div className="infoContainer">
                            <Image src={mailIcon} className="navIcon" />
                            <p><a href="mailto: hello@ej-software.co.uk">hello@ej-software.co.uk</a></p>
                        </div>
                        <div className="infoContainer">
                            <Image src={phoneIcon} className="navIcon" />
                            <p>01223 944093</p>
                        </div>
                    </div>
                    <div className="rightSide">
                        <div className="burgerHolder">
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleOpen} />
                                <Navbar.Collapse id="basic-navbar-nav" className="dropDownMenuTest" >
                                    <Nav className="me-auto navItems">
                                        <Nav.Link onClick={handleOpen} as={Link} eventKey="1" to="/"><li className="burgerNavElement">Home</li></Nav.Link>

                                        <NavDropdown title="Services" className="burgerSubMenu mainNavLink burgerBorderHider" right>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/services-bespoke-crm">Bespoke CRM</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/services-retail-and-ecommerce-solutions">Retail & Ecommerce</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/services-business-web-portals">Business Web Portals</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/services-iot">IoT</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/services-ai-and-machine-learning-solutions">AI & Machine Learning</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/services-business-process-and-software-consulting">Business Consultancy</NavDropdown.Item>
                                        </NavDropdown>
                                      
                                        <Nav.Link as={Link} onClick={handleOpen} eventKey="3" to="/team"><li className="burgerNavElement">Team</li></Nav.Link>
                                        <Nav.Link onClick={handleOpen} as={Link} eventKey="1" to="/blog"><li className="burgerNavElement">Blog</li></Nav.Link>

                                        <NavDropdown title="Case Studies" className="burgerSubMenu mainNavLink burgerBorderHider" drop={"down"}>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/case-study-fresh-check">FreshCheck</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handleOpen} as={Link} to="/case-study-goddards">Goddards</NavDropdown.Item>
                                        </NavDropdown>


                                        <Nav.Link as={Link} eventKey="5" to="/contact-us"><li className="burgerNavElement">Contact Us</li></Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </div>
                        <div className="socContainer">

                            <a style={{minWidth: 32, minHeight: 32}} href="https://www.facebook.com/profile.php?id=100094303763892" target="_blank" rel="noreferrer"><Image src={facebookicon} className="socIcon" /></a>
                            <a style={{ minWidth: 32, minHeight: 32 }} href="https://www.instagram.com/ejsoftware/" target="_blank" rel="noreferrer"><Image src={instagramicon} className="socIcon" /></a>
                            <a style={{ minWidth: 32, minHeight: 32 }} href="https://www.linkedin.com/company/91577331" target="_blank" rel="noreferrer"><Image src={linkedinicon} className="socIcon" /></a>
                            <a style={{ minWidth: 32, minHeight: 32 }} href="https://www.twitter.com/ejsoftware" target="_blank" rel="noreferrer"><Image src={xicon} className="socIcon" /></a>
                        </div>
                    </div>

                </div>

                <div className="containerTest2">
                    <Link to="/" className="imageDiv">
                        <Image src={logo} className="navbar-image" />
                    </Link>
                    <div className="listDiv">
                        {location.pathname !== "/book-in" && (
                            <Nav className="bootstrapUL">
                                <Nav.Link as={Link} to="/" className={`mainNavLink ${location.pathname === '/' && 'activeLink'}`}>Home</Nav.Link>

                                <NavDropdown onClick={(e) => navigateToServices(e)} title="Services" className={`seeMe mainNavLink serviceDropDown ${location.pathname.startsWith('/service') && 'activeLink'}`} show={dropdownOpenService} onMouseEnter={toggleDropdownService} onMouseLeave={toggleDropdownService}>
                                    <NavDropdown.Item as={Link} to="/services-bespoke-crm">Bespoke CRM</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/services-retail-and-ecommerce-solutions">Retail & Ecommerce</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/services-business-web-portals">Business Web Portals</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/services-iot">IoT</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/services-ai-and-machine-learning-solutions">AI & Machine Learning</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/services-business-process-and-software-consulting">Business Consultancy</NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link as={Link} to="/team" className={`mainNavLink ${location.pathname === '/team' && 'activeLink'}`}>Team</Nav.Link>
                                <Nav.Link as={Link} to="/blog" className={`mainNavLink ${location.pathname === '/blog' && 'activeLink'}`}>Blog</Nav.Link>

                                <NavDropdown onClick={(e) => navigateToCaseStudy(e)} title="Case Studies" className={`seeMe mainNavLink caseStudyButton ${location.pathname.startsWith('/case') && 'activeLink'}`} show={dropdownOpenCaseStudy} onMouseEnter={toggleDropdownCaseStudy} onMouseLeave={toggleDropdownCaseStudy}>
                                    <NavDropdown.Item as={Link} to="/case-study-fresh-check">FreshCheck</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/case-study-goddards">Goddards</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link as={Link} to="/contact-us" className="contactUs mainNavLink">Contact Us</Nav.Link>
                            </Nav>
                        ) }
                    </div>
                </div>


                {/*</Navbar.Brand>*/}
            </Navbar>
        </header>
    );
}

export default NavMenu