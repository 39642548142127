import React from 'react';
import {Link} from 'react-router-dom'
import { Image } from 'react-bootstrap';
import '../../assets/css/casestudies.scss'
import freshCheck from '../../assets/images/freshCheckCaseStudy.jpg'
import goddardsGroup from '../../assets/images/goddardsCaseStudy.jpg'


function CaseStudies() {
    return (
        <div className="caseSeg">
            <div className="textSeg">
                <h2>Case Studies</h2>
                <p>Embark on a rollercoaster of triumphs with EJ Software, where challenges are mere stepping stones,
                    and our tailored solutions redefine success in every line of code.</p>
                <div className="imageSeg">
                    <Link to="/case-study-fresh-check" className="caseCard">
                        <div>
                            <Image src={freshCheck} className="caseImage" />
                            <p>Fresh check - Hygiene Verification System</p>
                        </div>
                    </Link>
                    
                    <Link to="/case-study-goddards" className="caseCard">
                        <div>
                            <Image src={goddardsGroup} className="caseImage" />
                            <p>Goddards - Inventory Management Solution</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CaseStudies
