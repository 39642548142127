import TeamPageComponent from '../components/home/MeetTheTeam'
import { Row } from 'react-bootstrap';
function TeamPage() {
    return (
        <div>
            <Row className="row-center">
                <TeamPageComponent />
            </Row>
        </div>
    )
}

export default TeamPage