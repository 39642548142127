import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './pages/Layout';
import PageReset from './components/utilityComps/PageReset';

function App() {
  const displayName = App.name;

    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
            </Routes>
            <PageReset />
      </Layout>
    );
}

export default App;