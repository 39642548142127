import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import '../../assets/css/landingextra.scss'

function LandingExtra() {
    return (
        <div className="mainExtraDiv">
            <div className="extraDiv">
                <h3 className="extraText">"And we now have a system that can grow with us and can become whatever we need it to be, presenting exciting opportunities for the future as we look at the other activities in the business." - Goddards</h3>
                <Button className="extraButton" as={Link} to="/case-study-goddards">Read More</Button>
            </div>
        </div>
    )
}

export default LandingExtra