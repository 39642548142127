import React from 'react'
import '../../assets/css/howwecanhelp.scss'
function HowWeCanHelpComp(props) {
    return (
        <div className="helpContainer">
            <div className="helpTextContainer">
                <h2>{props.title}</h2>
                <h3>{props.subTitle1}</h3>
                <p>{props.desc1}</p>
                <h3>{props.subTitle2}</h3>
                <p>{props.desc2}</p>
            </div>
        </div>
    );
}

export default HowWeCanHelpComp