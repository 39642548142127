import React from 'react'
import '../../assets/css/theoutcome.scss'

function TheOutcome() {
    return (
        <div className="outcomeContainer">
            <div className="textContainer">
                <h3>The Challenge</h3>
                <p>As the number of stores grew, it became increasingly obvious to Goddards' leadership team that their inventory management system was no longer fit for purpose. It was slow, challenging to use, and had little scope to be adapted to meet the business's needs, particularly as sales increased and greater stock control and visibility was required.
                    Inventory management tasks that should have taken minutes to complete were taking hours, and there was limited capability for the company to assign garments to individual stores based on real time sales data.</p>
                <p>In addition, the system was unable to integrate with services such as Shopify or provide staff working in the stores with an easy way to track stock and optimise sales.</p>
                <p>Faced with this situation and having tried in-house development previously, Goddards turned to EJ Software after they were recommended to them.</p>
                
                <h3>The Solution</h3>
                <p>Having taken the time to fully understand the challenge facing the business, EJ Software quickly realised they needed to develop a bespoke solution for them that not only met their current needs but also scaled and could be adapted as they grew. A new inventory management system that provided real time stock data to staff in stores and to the central buying team and management, and integrated with third party systems, whilst being quick and easy to use.</p>
                <p>In addition, the new system had to be robust and flexible enough to include thousands of stock lines (SKUs) and greatly simplify many of the key activities involved in the end-to-end sales process, from buying from manufacturers through to auto replenishment of sold items and auditing.</p>
                <p>Armed with these requirements, EJ Software created Orange Square, an inventory management system developed exclusively for Goddards and named after their head office.</p>
                <p>The fully bespoke system integrates with Shopify and other systems, enables store staff to quickly check stock levels and undertake stock control using mobile handheld devices, and allows head office personnel to see live data that helps them make quicker and more strategic business decisions.</p>
                <p>The system also streamlines tasks that were previously time-consuming and complex, and has been built so that it grows with the business, with the capability to automate and streamline other key activities in the future.</p>

                <h3>The Outcome</h3>
                <p>Launched in Q4 2023, phase one of Orange Square is having a transformational impact on Goddards' business.</p>
                <p>Purchase order management that previously took a whole day to complete is now done in just a few minutes, and stock control activities such as pre-ordering that rely on sales data can now be done earlier than ever before.</p>
                <p>Rolled out to all 10 stores in the Group, the new system has also enabled sales staff to streamline and optimise time-consuming processes and activities, and have greater visibility of stock levels and availability.</p>
                <p>In addition, head office staff can instantly see and track inventory across all the stores, see live sales performance, and allocate stock quickly and easily based on sales trends.</p>
            </div>
        </div>
    );
}

export default TheOutcome;