import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/servicedesc.scss'

import techImage from '../../assets/images/techImage.png'

function ServiceDescComp(props) {
    return (
        <div className="serviceContainer">
            <h2>{props.pageTitle}</h2>
            <div className="serviceInfoContainer">
                <div className="serviceText">
                    <p>{props.subDesc}</p>
                    <h3>{props.serviceTitle}</h3>
                    <p>{props.desc}</p>
                </div>
                <div className="serviceImage">
                    <Image src={props.image} className="techImage"/>
                </div>
            </div>
        </div>
    );
}

export default ServiceDescComp