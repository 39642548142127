import React from 'react'
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import '../../assets/css/aboutej.scss'
import daBoyz from '../../assets/images/daBoyz.jpg'

function AboutEj() {
    return (
        <div className="aboutEjContainer">
            <div className="contentContainer">
            
                <div className="aboutEjLeft">
                    <h3>About EJ Software</h3>
                    <p>EJ Software are specialists in building bespoke software and lasting relationships with companies of all sizes from numerous sectors.</p>
                    <p>Established by experienced developers Elliott Hicks and Justin Donnelly, the company has created tailored software solutions for businesses including the Goddards, Locyl, Wire Wizard, CambCol, FreshCheck, and many more.</p>
                    <p>But as well as building full systems for established businesses, the company also works with many start-up enterprises, developing Proof of Concept and Minimal Viable Product (MVP) services that enable them to validate their idea and attract investment.</p>
                    <p>In addition, EJ Software provides expert technical consultancy to help clients achieve their goals and grow.</p>
                    <div className="ejButton">
                        <Button className="buttonStyle" as={Link} to="/contact-us">Start your software journey!</Button>
                    </div>
                </div>
                <div className="aboutEjRight">
                    <div className="borderDiv"></div>
                    <Image src={daBoyz} className="ejFounder"/>
                </div>
            </div>
        </div>
    );
}

export default AboutEj;