import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import '../../assets/css/discovery.scss'

function Discovery() {
    return (
        <div className="mainDiscoveryDiv">
            <div className="discoveryDiv">
                <h3>Discover how we can help you transform your retail management</h3>
                <Button className="landingButton" as="a" href="https://calendly.com/elliotthicks/step-into-the-future-of-retail" target="_blank" rel="noreferrer">Book a FREE discovery call</Button>
            </div>
        </div>
    )
}

export default Discovery