import '../../assets/css/linksContainer.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

function LinkComponent() {
    const navigate = useNavigate();

    const linkArr = [
        {
            name: "LinkedIn",
            url: "https://www.linkedin.com/company/ej-software",
            icon: "fa-brands fa-linkedin",
        },
        {
            name: "X",
            url: "https://x.com/EJSoftware",
            icon: "fa-brands fa-x-twitter",
        },
        {
            name: "Instagram",
            url: "https://www.instagram.com/ejsoftware/",
            icon: "fa-brands fa-instagram",
        },
        {
            name: "Facebook",
            url: "https://www.facebook.com/profile.php?id=100094303763892",
            icon: "fa-brands fa-facebook-f",
        },
        {
            name: "Email Us",
            url: "mailto:hello@ej-software.co.uk",
            icon: "fa-solid fa-envelope",
        },
        {
            name: "Book a call",
            url: "/book-in",
            icon: "fa-solid fa-phone",
        },
        {
            name: "Contact us",
            url: "/contact-us",
            icon: "fa-solid fa-address-book",
        }
    ]

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                //alert('URL copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };


    const handleClick = (e, link) => {
        if (!e.target.classList.contains('fa-copy')) {
            if (link.url.startsWith('http') || link.url.startsWith('mailto')) {
                window.open(link.url, '_blank');
            } else {
                navigate(link.url);
            }
        }
    };

    return (
        <div className="linkSeg pb-5">
            <div className="linkSegOne">
                <h2>Keep up to date</h2>
                <p>Curious about what we're working on or getting in touch? Here's where we are</p>
            </div>
            <div className="linkSegTwo mt-2 mb-2">
                {linkArr.map((link, index) => {
                    return (
                        <div key={index}>
                            {link.url.startsWith('http') || link.url.startsWith('mailto') ? (
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                    <i className={link.icon} />
                                </a>
                            ) : (
                                <Link to={link.url}>
                                    <i className={link.icon} />
                                </Link>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="linkSegThree mt-2 mb-2">
                {linkArr.map((link, index) => {
                    return (
                        <div className="linkContainer" key={index} onClick={(e) => handleClick(e, link)}>
                            <i className={link.icon} />
                            <p>{link.name}</p>
                            <i
                                className="fa-regular fa-copy"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    copyToClipboard(link.url)
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default LinkComponent