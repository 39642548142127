import React from 'react';
import { Row } from 'react-bootstrap';

import ServiceContactComp from '../components/servicesComponents/ServiceContactComp'
import ServiceDescComp from '../components/servicesComponents/ServiceDescComp';
import techImage from '../assets/images/consultancy.jpg'

import ServiceBenefitsComp from '../components/servicesComponents/ServiceBenefitsComp'

import serviceIcon1 from "../assets/images/serviceIcons/-_Strategic Planning.png"
import serviceIcon2 from "../assets/images/serviceIcons/-_Operational Optimization.png"
import serviceIcon3 from "../assets/images/serviceIcons/serviceIconExpansion.png"

import HowWeCanHelpComp from '../components/servicesComponents/HowWeCanHelpComp';

import ServiceCardsComp from '../components/servicesComponents/ServiceCardsComp'


function BusProcSoftwareConsult() {


    const benefitsArray = [
        {
            title: "Strategic Planning",
            desc: "Forge a path to success with strategic planning. Our Business Consultancy services assist you in developing a clear and actionable strategy that aligns with your business goals and market dynamics.",
            cardImage: serviceIcon1
        },
        {
            title: "Operational Optimization",
            desc: "Enhance efficiency and reduce costs. Our consultants analyze your current operations, identify bottlenecks, and propose solutions to streamline processes, ensuring optimal resource utilization.",
            cardImage: serviceIcon2
        },
        {
            title: "Market Expansion",
            desc: "Navigate the complexities of market expansion with confidence. Our Business Consultancy services provide insights, market research, and strategic guidance to help you successfully enter new markets and reach new audiences.",
            cardImage: serviceIcon3
        },
    ]

    return (
        <div>
            <Row className="row-center">
                <ServiceDescComp pageTitle="Strategic Excellence: Elevate Your Business with Business Consultancy"
                    subDesc="At EJ Software, we're not just consultants; we're strategic partners dedicated to propelling your business towards success. Introducing our Business Consultancy services - where expertise meets innovation."
                    serviceTitle="What is Business Consultancy?"
                    desc="Business Consultancy is a holistic approach to providing expert advice and solutions to businesses seeking to improve performance, efficiency, and overall effectiveness. Our consultancy services cover a spectrum of business challenges, from strategic planning to process optimization."
                    image={techImage} />
            </Row>
            <Row className="row-center">
                <HowWeCanHelpComp desc1={`"Business consultancy is the compass guiding enterprises through the complex landscape of challenges and opportunities. It's the art of distilling wisdom from experience, providing strategic insights, and crafting tailored solutions. In the realm of business consultancy, every recommendation is a step towards unlocking untapped potential and achieving sustainable success in an ever-evolving marketplace."`} />
            </Row>
            <Row className="row-center">
                <ServiceBenefitsComp title="The benefits of choosing Business Consultancy" benefits={benefitsArray} />
            </Row>
            <Row className="row-center">
                <ServiceCardsComp />
            </Row>
            <Row className="row-center">
                <ServiceContactComp title="Elevate your business strategy"
                    desc="Contact us today to explore how our Business Consultancy services can drive your business towards sustainable growth and success." />
            </Row>
        </div>
    );
}

export default BusProcSoftwareConsult