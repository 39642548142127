import React, { useState } from 'react'; // Don't forget to import useState
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../../assets/css/mailinglist.scss';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

function Contact() {
    const [messageSent, setMessageSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    // Move the MyForm component outside of the Contact function
    const MyForm = () => {
        const formik = useFormik({
            initialValues: {
                email: ''
            },
            validationSchema: validationSchema,
            onSubmit: async values => { // Add async keyword to onSubmit function
                try {
                    let url = `ContactUs/JoinMailingList`;
                    const response = await fetch(url, { // Remove unnecessary parentheses around 'url'
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json", // Use an object for headers
                        },
                        body: JSON.stringify(values.email),
                    });
                    const body = await response.json();



                    console.log("here", body)
                    if (body === true) {
                        setMessageSent(true);
                        setSendError(false); // Reset the error state
                    } else {
                        console.log("bad result")
                        setSendError(true);
                        setMessageSent(false); // Reset the success state
                    }
                } catch (err) {

                    console.log("full error")
                    setSendError(true); // Handle fetch errors by setting error state
                    setMessageSent(false); // Reset the success state
                }
            },
        });

        return (
            <form className="footerForm" onSubmit={formik.handleSubmit}>

                <div>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Email"
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="contact-error footerError">{formik.errors.email}</div>
                    ) : null}
                </div>
                <center>
                    <button className="submitButton" type="submit">Stay Updated</button>
                </center>
            </form>
        );
    };

    return (
        <>
           {!messageSent &&
                <div className="footerFormOuter">
                    {!messageSent && (
                        <>
                            <MyForm />
                        </>
                    )}
                    {sendError && (
                        <>
                            <h5>There was an error sending your message. If this persists, please send an email to  <a href="mailto:hello@ej-software.co.uk">hello@ej-software.co.uk</a></h5>
                        </>
                    )}
                </div>
            }
            {messageSent && (
                <div>
                    <h4 style={{color: "white"}}>Thanks for joining our mailing list.</h4>
                </div>
            )}
        </>
    );
}

export default Contact;

