import React from 'react'
import { Image } from 'react-bootstrap';
import '../../assets/css/servicebenefits.scss'



function ServiceBenefitsComp(props) {

    const mapImages = () => {
        return props.benefits.map((e,index) => 
            <div className={index%2===0 ? "rightImageDiv" : "leftImageDiv" }>
                <div className="benefitTextDiv">
                    <h3>{e.title}</h3>
                    <p>{e.desc}</p>
                </div>
                <div className="benefitImageDiv">
                    <Image src={e.cardImage} className="benefitsImage" />
                </div>
            </div>
        )
    }

    return (
        <div className="benefitsContainer">
            <div className="benefitsContentDiv">
                <h2>{props.title}</h2>
                {mapImages()}
            </div>
        </div>
    );
}

export default ServiceBenefitsComp