import ServicePage from '../components/ServicesPage/Offering'
import { Row } from 'react-bootstrap';
function ServicesPage() {
    return (
        <div>
            <Row className="row-center">
                <ServicePage />
            </Row>
        </div>
    )
}

export default ServicesPage