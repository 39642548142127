//Landing
import RetailLanding from "./pages/RetailLanding"
//Pages
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import ServicesPage from "./pages/ServicesPage"
import TeamPage from "./pages/Team";
import ContactUsPage from "./pages/ContactUsPage";
//Case Studies
import CaseStudy from "./pages/CaseStudy"
import FreshCheck from "./pages/FreshCheck";
import Goddards from "./pages/Goddards";
//Services
import BespokeCRM from "./pages/BespokeCRM";
import RetailEcommerce from "./pages/RetailEcommerce"
import BusinessWebPortals from "./pages/BusinessWebPortals"
import IoT from "./pages/IoT"
import AIMachineLearning from "./pages/AIMachineLearning"
import BusProcSoftwareConsult from "./pages/BusProcSoftwareConsult"
//Other
import BookIn from "./pages/BookIn"
import LinkComponent from "./components/Links/LinkComponent";
import Resources from "./pages/Resources";
import Blog from "./pages/Blog";
import BlogEntry from "./pages/BlogEntry";



const AppRoutes = [
    {
        path: '/step-into-the-future-of-retail',
        element: <RetailLanding />
    },
    {
        index: true,
        element: <Home />
    },
    {
        path: '/privacy',
        element: <Privacy />
    },
    {
        path: '/book-in',
        element: <BookIn />
    },
    {
        path: '/services',
        element: <ServicesPage />
    },
    {
        path: '/team',
        element: <TeamPage />
    },
    {
        path: '/contact-us',
        element: <ContactUsPage />
    },
    {
        path: '/case-study',
        element: <CaseStudy />
    },
    {
        path: '/case-study-fresh-check',
        element: <FreshCheck />
    },
    {
        path: '/case-study-goddards',
        element: <Goddards />
    },
    {
        path: '/services-bespoke-crm',
        element: <BespokeCRM />
    },
    {
        path: '/services-retail-and-ecommerce-solutions',
        element: <RetailEcommerce />
    },
    {
        path: '/services-business-web-portals',
        element: <BusinessWebPortals />
    },
    {
        path: '/services-iot',
        element: <IoT />
    },
    {
        path: '/services-ai-and-machine-learning-solutions',
        element: <AIMachineLearning />
    },
    {
        path: '/services-business-process-and-software-consulting',
        element: <BusProcSoftwareConsult />
    },
    {
        path: '/links',
        element: <LinkComponent />
    },
    {
        path: '/resources',
        element: <Resources />
    },
    {
        path: '/resources/:resource',
        element: <Resources />
    },
    {
        path: '/blog',
        element: <Blog />
    },
    {
        path: '/blog/:article',
        element: <BlogEntry />
    }
];

export default AppRoutes;
