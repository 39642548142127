import { Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ResourceDesc from '../components/resources/ResourceDesc';

function Resources() {
    const { resource } = useParams();
    const [pathResource, setPathResource] = useState(null);

    const resList = {
        pdf: {
            title: "This awesome PDF file that does things",
            para1: "This is just some placeholder text, read about the file here. It does stuff, magical stuff even. Download the file. Do it. You know you want to.",
            para2: "This is the second line of the placeholder text, you should also read this stuff. You can download the magical file below!",
            file: ""
        },
        xml: {
            title: "Who even uses xml anymore? Download and find out!",
            para1: "This is just some placeholder text, read about the file here. It does stuff, magical stuff even. Download the file. Do it. You know you want to.",
            para2: "This is the second line of the placeholder text, you should also read this stuff. You can download the magical file below!",
            file: ""
        }
    };

    const navigateList = [
        {
            link: "pdf",
            title: "PDF"
        },
        {
            link: "xml",
            title: "XML"
        }
    ];

    const setResource = (res) => {
        if (res && resList[res]) {
            setPathResource(resList[res]);
        } 
    };

    useEffect(() => {
        if (resource) {
            setResource(resource);
        } else {
            setPathResource(null)
        }
    }, [resource]);

    return (
        <div>
            <Row className="row-center">
                <ResourceDesc resData={pathResource} resLinks={navigateList} />
            </Row>
        </div>
    );
}

export default Resources;
