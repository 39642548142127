import { Row } from 'react-bootstrap';
import BlogContainer from '../components/blog/BlogContainer';
function Blog() {
    return (
        <div>
            <Row className="row-center">
                <BlogContainer />
            </Row>
        </div>
    )
}

export default Blog